import {mapMutations, mapActions, mapGetters} from "vuex";
import {dynamicHead} from "../../mixins/dynamic-head";

//sections


export default {
    name: "manuals",
    components: {},
    mixins: [dynamicHead],
    data() {
        return {
            seoData: ''
        }
    },
    beforeUpdate() {

    },
    mounted() {

        this.getPageContent('manuals').then(() => {
            this.setPageItem(this.pageContent)

            this.setBannerContent(this.pageContent)
            this.seoData = this.pageContent.seo.data
        })
        this.getManuals().then(() => {
            this.getBannerImage()
            document.title = this.$t('manuals.title')
        })
        this.setRequestFlag(true);
    },
    watch: {
        '$route.params.slug': {

            // handler: function () {
            //     // document.title = `${this.pageContent.title}`
            //     // this.getBannerImage()
            // },
        }
    },
    created() {

        this.getManuals().then(() => {
            this.getBannerImage()
        })
        // // this.getPageContent('manuals')
        // this.setRequestFlag(true);
    },

    computed: {
        ...mapGetters({
            manuals: 'manuals/manuals',
            pageContent: 'setting/pageContent',
            globalRequestsFlag: 'system/globalRequestsFlag',
            manualPage: 'manuals/manualPage',
            bannerContent: 'manuals/bannerContent',
        }),

    },

    methods: {
        ...mapActions({
            getManuals: 'manuals/GET_MANUALS',
            getPageContent: 'setting/GET_PAGE_CONTENT',
            getPage: 'manuals/GET_MANUAL_PAGE_OR_GROUP',

        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
            setBannerContent: 'manuals/SET_BANNER_CONTENT',
        }),
        getBannerImage() {
            console.log(3);
            let currentManual

            this.manuals.find(el => {
                if (el.slug === this.$route.params.slug) {
                    currentManual = el
                }
            })
            if (!currentManual && this.$route.params.slug) {
                this.getPage(this.$route.params.slug).then(()=>{
                    return this.setBannerContent(this.manualPage)

                })

            }
            if (!currentManual && !this.$route.params.slug) {
                return this.setBannerContent(this.pageContent)

            }
            else {
                console.log(currentManual,'currentManual');
                return this.setBannerContent(currentManual)
            }
        },
    }
}
